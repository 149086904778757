import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormCheck } from 'react-bootstrap';
import { useCreateNewProjectMutation } from 'modules/dashboard/mutations/useCreateNewProjectMutation';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import Notiflix from 'notiflix';

const CreateNewProjectForm = ({ hideModal }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues: { name: '' , field_setup_instructionsISsmallplaintextbox:'' } });

  const queryClient = useQueryClient();
  const existedProjects = queryClient.getQueryData(['ALL_PROJECTS']);
  const { mutate: createNewProject } = useCreateNewProjectMutation();

  const onSubmit = ({ name, field_setup_instructionsISsmallplaintextbox, post_extraction_instructionsISsmallplaintextbox, do_not_auto_extract_fieldsYN }) => {
    const projectExists = existedProjects.some(project => project.name.toLowerCase() === name.toLowerCase());

    if (projectExists) {
      return Notiflix.Notify.warning(`${name} is already in projects`);
    }
    createNewProject({ name, field_setup_instructionsISsmallplaintextbox, post_extraction_instructionsISsmallplaintextbox, do_not_auto_extract_fieldsYN }, { onSuccess: hideModal });
  };

  return (
    <Form className="mt-2" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          placeholder="Enter a project name"
          isInvalid={!!errors.name}
          {...register('name', { required: 'Name is required' })}
          type="text"
        />
        {errors.name && <Form.Control.Feedback type="invalid">{errors.name.message}</Form.Control.Feedback>}
      </Form.Group>

      <Form.Group className="mb-3">
        <FormCheck
            type="checkbox"
            id="do_not_auto_extract_fieldsYN"
            label="Don't Auto-extract Fields"
            style={{ marginRight: '11rem' }}
            {...register('do_not_auto_extract_fieldsYN', {  })}
          />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Build Fields and Rules Automatically?</Form.Label>
        <Form.Control
          as="textarea"
          type="text"
          rows={6}
          placeholder="Optional: describe the fields and rules you want to build from the files you will add to this project, eg: I want to extract the name of the primary person, the date of the event, the location of the event and a 2 sentence summary of the text..."
          {...register('field_setup_instructionsISsmallplaintextbox', {  })}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Post-extraction Instructions</Form.Label>
        <Form.Control
          as="textarea"
          type="text"
          rows={6}
          placeholder="Optional: provide post-OCR extraction instructions, eg: Correct spelling and grammar, or remove any personal information, or summarise into 5 sentences..."
          {...register('post_extraction_instructionsISsmallplaintextbox', {  })}
        />
      </Form.Group>

      <Form.Group className="my-2">
        <Button className="w-100 bg-dark" type="submit">
          Create
        </Button>
      </Form.Group>
    </Form>
  );
};

CreateNewProjectForm.propTypes = {
  hideModal: PropTypes.func.isRequired
};

export default CreateNewProjectForm;
