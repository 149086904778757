import { useMutation, useQueryClient } from 'react-query';
import Notiflix from 'notiflix';
import { loadingSvgColor, loadingTextColor } from 'helpers/utils';
import axios from 'axios';

const uploadImagesOfProject = async ({ files, id, allow_duplicate_file_names }) => {
  let uploadedFilesCount = 0;
  const totalFiles = files.length;
  let results = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const reader = new FileReader();

    await new Promise((resolve, reject) => {
      reader.onerror = () => reject('Error reading file.');

      reader.onload = () => {
        const stringFiles = { name: file.name, size: file.size, type: file.type, data: reader.result };
        const imgData = {
          projectISbb_datasheep_projectsID: id,
          name: stringFiles.name,
          imageISfile: stringFiles,
          allow_duplicate_file_names
        };

        axios
          .post(`/app/bb_datasheep_projects_files`, imgData)
          .then(response => {
            uploadedFilesCount++;

            const overallProgress = Math.round((uploadedFilesCount / totalFiles) * 100);

            Notiflix.Loading.change(`Current ${overallProgress}%, uploaded ${uploadedFilesCount} of ${totalFiles} files.`);

            results.push(response.data);
            resolve(response.data);
          })
          .catch(error => {
            reject(error.response?.data || error.message);
          });
      };

      reader.readAsDataURL(file);
    });
  }
  return results;
};

export const useUploadFilesMutation = id => {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: ({ files, id, allow_duplicate_file_names }) => uploadImagesOfProject({ files, id, allow_duplicate_file_names }),
    onMutate: () => {
      Notiflix.Loading.hourglass('Starting file upload...', {
        svgColor: loadingSvgColor,
        messageFontSize: '18px',
        messageColor: loadingTextColor
      });
    },
    onError: error => {
      Notiflix.Notify.failure(error.message);
    },
    onSuccess: async results => {
      const errors = results?.filter(result => result?.status === 'rejected' || result?.error);
      if (errors.length === 0) {
        console.log('Upload status:', 'All files uploaded successfully!');
      }
      await queryClient.invalidateQueries(['GET_PROJECT_FILES', id]);
    },
    onSettled: () => {
      Notiflix.Loading.remove();
    }
  });

  const uploadFiles = ({ files, allow_duplicate_file_names }, options = {}) => {
    mutate(
      { files, id, allow_duplicate_file_names },
      {
        ...options,
        onSuccess: (data, variables, context) => {
          if (options.onSuccess) {
            options.onSuccess(data, variables, context);
          }
        }
      }
    );
  };

  return { uploadFiles };
};
