import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Footer = () => (
  <footer>
    <Row className="justify-content-between text-center fs--1 mt-4 mb-5">
      <Col sm="auto">
        <p className="mb-0 text-600">
          <a href="https://www.blueboxonline.com/privacy" target="_blank" rel="noreferrer noopener">
            BlueBox UK Terms
          </a>{' '}
          <span className="d-none d-sm-inline-block"> | </span>
          <br className="d-sm-none" /> {new Date().getFullYear()} &copy; &nbsp;
        </p>
      </Col>
      <Col sm="auto">
        <p className="mb-0 text-600">v2.27</p>
      </Col>
    </Row>
  </footer>
);

export default Footer;
