import React, { useMemo, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import { useParams } from 'react-router-dom';
import { Button, Modal, FormCheck } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import Notiflix from 'notiflix';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { getSize } from 'helpers/utils';
import { useUploadFilesMutation } from './mutations/useUploadFilesMutation';
import './FieldsAddImagesForm.css';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FieldsAddImagesModal = ({ isVisible, closeModal }) => {
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const [errorLogs, setErrorLogs] = useState([]);
  const [allowDuplicates, setAllowDuplicates] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const { uploadFiles } = useUploadFilesMutation(id);

  const handleSubmit = () => {
    const payload = { files, allow_duplicate_file_names: allowDuplicates ? 1 : 0 };

    uploadFiles(
      { ...payload },
      {
        onSuccess: data => {
          const errors = data.filter(result => result?.status === 'rejected' || result?.value?.error || result?.error);
          setFiles([]);
          if (errors.length > 0) {
            setErrorLogs(errors.map(err => err?.reason || err?.value?.error || err?.error));
          } else {
            Notiflix.Notify.success(`${data.length} files uploaded successfully!`);
            handleCloseModal();
          }
        },
        onError: error => {
          Notiflix.Notify.failure(error?.error);
        },
        onSettled: () => {
          setIsUploading(false);
        }
      }
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(prev => [
        ...prev,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    }
  });

  const handleRemove = path => {
    setFiles(files.filter(file => file.path !== path));
  };

  const handleCloseModal = () => {
    closeModal();
    setFiles([]);
    setErrorLogs([]);
  };

  const filesCountToDisplayText = useMemo(() => {
    return files.length === 1 ? '1 file to upload.' : `${files.length} files to upload.`;
  }, [files]);

  return (
    <Modal size="lg" show={isVisible} onHide={handleCloseModal} aria-labelledby="add-files-modal">
      <Modal.Header style={{ backgroundColor: errorLogs.length > 0 ? '#FFEFD0' : '' }}>
        <Modal.Title id="add-files-modal">{errorLogs.length > 0 ? 'Errors log' : 'Add Files'}</Modal.Title>
        <FalconCloseButton onClick={handleCloseModal} />
      </Modal.Header>
      <Modal.Body>
        {errorLogs.length > 0 ? (
          <ul className="m-0 list-unstyled">
            {errorLogs.map(err => (
              <li key={err}>{err}</li>
            ))}
          </ul>
        ) : (
          <div className="mt-2">
            <div className="mb-3">
              <div {...getRootProps({ className: 'dropzone-area py-6' })}>
                <input name="uploadedFiles" {...getInputProps()} />
                <Flex justifyContent="center" alignItems="center">
                  <FontAwesomeIcon className="me-2" icon={faCloudUploadAlt} />
                  <p className="fs-0 mb-0 text-700">Drop your images here</p>
                </Flex>
                <Flex justifyContent="center" alignItems="center">
                  <p className="fs-0 mb-0 text-warning">
                    <small>
                      Note: This is a bulk upload process and images text/fields are extracted incrementally, once each file is uploaded.
                      <br />
                      Credits will be consumed based on the number of files uploaded and the number of fields/rules processed.
                      <br />
                      If credits run out in during the process extraction will stop, and the remaining files will be uploaded without data.
                    </small>
                  </p>
                </Flex>
              </div>
            </div>
            {files.length > 0 && (
              <>
                <div className="files-info">
                  <p className="text-center flex-grow-1 py-1 m-0 fs-0">{filesCountToDisplayText}</p>
                  <Flex alignItems="center" justifyContent="between" className="gap-2">
                    <FormCheck
                      type="checkbox"
                      id="allow-duplicates"
                      label="Allow duplicate file names"
                      checked={allowDuplicates}
                      onChange={e => setAllowDuplicates(e.target.checked)}
                      style={{ marginRight: '11rem' }}
                    />
                    <Button variant="dark" className="files-info--upload" onClick={handleSubmit} disabled={isUploading}>
                      {isUploading ? (
                        <>
                          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                          Uploading...
                        </>
                      ) : (
                        'Upload files'
                      )}
                    </Button>
                  </Flex>
                </div>
                {files.map(file => (
                  <Flex alignItems="center" className="py-3 border-bottom btn-reveal-trigger" key={file.path}>
                    <Zoom>
                      <img className="rounded" width={40} height={40} src={file.preview} alt={file.path} />
                    </Zoom>
                    <Flex justifyContent="between" alignItems="center" className="ms-3 flex-1">
                      <div>
                        <h6 style={{ width: '200px', overflow: 'hidden' }}>{file.path}</h6>
                        <Flex className="position-relative" alignItems="center">
                          <p className="mb-0 fs--1 text-400 line-height-1">
                            <strong>{getSize(file.size)}</strong>
                          </p>
                        </Flex>
                      </div>
                    </Flex>
                    <IconButton className="ms-2 mb-0" variant="light" size="sm" icon="trash" onClick={() => handleRemove(file.path)} />
                  </Flex>
                ))}
              </>
            )}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

FieldsAddImagesModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default FieldsAddImagesModal;
