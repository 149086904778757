import React from 'react';
import { Dropdown, Nav } from 'react-bootstrap';
import Notiflix from 'notiflix';
import { useChangeBalanceMutation } from 'hooks/mutations/useChangeBalanceMutation';
import useCreditsDisplayMutation from 'hooks/mutations/useCreditsDisplayMutation';
import { Loader } from 'components/common/Loader';
import { useGetPaymentLinksQuery } from 'hooks/queries/useGetPaymentLinksQuery';

const CreditsDropdown = () => {
  const { data: ddata, isLoading: isLoadingBalance, error: balanceError } = useCreditsDisplayMutation();
  const { data: paymentLinks, isLoading: isLoadingLinks, error: linksError } = useGetPaymentLinksQuery();

  const handleTopupClick = amount => {
    if (!paymentLinks) return;

    const stripeUrls = {
      100: paymentLinks['Credit-Purchase-100'],
      50: paymentLinks['Credit-Purchase-50'],
      25: paymentLinks['Credit-Purchase-25'],
      10: paymentLinks['Credit-Purchase-10']
    };

    const width = 500;
    const height = 700;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const newWindow = window.open(stripeUrls[amount], '_blank', `width=${width},height=${height},top=${top},left=${left}`);

    const checkPaymentWindow = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(checkPaymentWindow);
        refreshBalance();
      }
    }, 500);
  };

  const { mutate: refreshBalance } = useChangeBalanceMutation({
    onSuccess: () => {
      Notiflix.Notify.success('Balance updated successfully');
    },
    onError: () => {
      Notiflix.Notify.failure('Failed to update balance');
    }
  });

  if (isLoadingBalance || isLoadingLinks) {
    return (
      <section className="loader-section">
        <Loader />
      </section>
    );
  }

  if (balanceError || linksError) {
    return (
      <Dropdown.Toggle variant="falcon" className="w-100 bg-transparent border-0 outline-none">
        Error
      </Dropdown.Toggle>
    );
  }

  return (
    <>
      <Nav.Item className="d-none d-lg-block" style={{fontWeight:'normal',color:'blue',marginRight:'15px'}}>
        {ddata.processing ? `Files in queue: ${ddata.processing}` : ''}
      </Nav.Item>
      <Nav.Item className="d-none d-lg-block" style={{fontWeight:'normal'}}>
          Total Files: {ddata.total_file_storage ? `${ddata.total_file_storage}` : '0.00'} GB
      </Nav.Item>
      <Dropdown navbar={true} as="li">
        <Dropdown.Toggle variant="falcon" className="w-100 bg-transparent border-0 outline-none" style={{color: (ddata.balance<=0?'red':'gray'),fontSize:'10pt'}}>
          Your Credits: {ddata.balance ? `${ddata.balance}` : '0.00'}
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-caret dropdown-menu-card dropdown-menu-end">
          <Dropdown.Item onClick={() => handleTopupClick(100)}>Topup 100</Dropdown.Item>
          <Dropdown.Item onClick={() => handleTopupClick(50)}>Topup 50</Dropdown.Item>
          <Dropdown.Item onClick={() => handleTopupClick(25)}>Topup 25</Dropdown.Item>
          <Dropdown.Item onClick={() => handleTopupClick(10)}>Topup 10</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as="a" href="/about-credits">
            About Credits
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default CreditsDropdown;
