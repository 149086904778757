import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { Button, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Notiflix from 'notiflix';
import ModalExtract from 'components/common/ModalExtract';
import { useExtractFilesMutation } from 'hooks/mutations/useExtractFilesMutation';
import { useTextExtractMutation } from 'hooks/mutations/useTextExtractMutation';
import { useChangeBalanceMutation } from 'hooks/mutations/useChangeBalanceMutation';
import { useExtractEmptyFilesMutation } from 'modules/project-view/mutations/useExtractEmptyFilesMutation';
import { useExtractEmptyTextMutation } from 'modules/project-view/mutations/useExtractEmptyTextMutation';
import { useExtractDataMutation } from 'modules/project-view/mutations/useExtractDataMutation';
import { getFiles } from 'modules/FilesTable/FilesTable';
import FilesSpecModal from './FilesSpecModal';
import FieldsSpecModal from './FieldsSpecModal';

const FieldsSideMenu = ({ setShowFilesModal }) => {
  const { id } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBodyText, setModalBodyText] = useState('');
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showFilesSpecModal, setShowFilesSpecModalLocal] = useState(false);
  const [showFieldsSpecModal, setShowFieldsSpecModalLocal] = useState(false);

  const data = { projectISbb_datasheep_projectsID: id };
  const queryClient = useQueryClient();

  const handleExport = data => {
    const a = document.createElement('a');
    a.href = data?.download;
    a.target = '_blank';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const onMutateHandler = () => {
    setModalTitle('Extracting…');
    setModalBodyText('Large datasets may take some time to complete...');
    setShowProgressBar(true);
    setShowModal(true);
    setProgress(0);
  };

  const onErrorHandler = data => {
    setModalTitle('Error');
    setModalBodyText(data.response.data.error);
    setShowProgressBar(false);
  };

  const onSuccessHandler = () => {
    setProgress(100);
    Notiflix.Notify.success('Extraction was completed');
    setShowProgressBar(false);
    setShowModal(false);
  };

  const { mutate: extractFiles } = useExtractFilesMutation(id, {
    onMutate: onMutateHandler,
    setProgress,
    onError: onErrorHandler,
    onSuccess: async data => {
      const fileErrors = Object.values(data.files || {}).filter(fileData => fileData.error);

      if (fileErrors.length > 0) {
        setModalTitle('Error');
        setModalBodyText(fileErrors[0].error);
        setShowProgressBar(false);
      } else {
        await changeBalance();
        Notiflix.Notify.success('Balance updated successfully');
      }
      setShowProgressBar(false);
      // queryClient.invalidateQueries(['GET_PROJECT_FILE_DETAILS', id]);
      // queryClient.invalidateQueries(['GET_PROJECT_FILES', id]);
      // queryClient.invalidateQueries('credits');
    }
  });

  const { mutate: extractEmptyFiles } = useExtractEmptyFilesMutation(id, {
    onMutate: onMutateHandler,
    setProgress,
    onError: onErrorHandler,
    onSuccess: async () => {
      changeBalance();
      onSuccessHandler();
      queryClient.invalidateQueries('credits');
    }
  });

  const { mutate: extractText } = useTextExtractMutation(id, {
    onMutate: onMutateHandler,
    setProgress,
    onError: onErrorHandler,
    onSuccess: async () => {
      changeBalance();
      onSuccessHandler();
      queryClient.invalidateQueries('credits');
    }
  });

  const { mutate: extractEmptyText } = useExtractEmptyTextMutation(id, {
    onMutate: onMutateHandler,
    setProgress,
    onError: onErrorHandler,
    onSuccess: async () => {
      changeBalance();
      onSuccessHandler();
      queryClient.invalidateQueries('credits');
    }
  });

  const { mutate: extractData } = useExtractDataMutation(id, {
    onMutate: onMutateHandler,
    setProgress,
    onError: onErrorHandler,
    onSuccess: onSuccessHandler
  });

  const { mutate: changeBalance } = useChangeBalanceMutation({
    onSuccess: () => {
      Notiflix.Notify.success('Balance updated successfully');
    },
    onError: error => {
      Notiflix.Notify.failure('Failed to update balance');
      console.error('Failed to update balance', error);
    }
  });

  const textExportHandler = () => {
    extractData(data, {
      onSuccess: data => handleExport(data),
      onError: error => console.error('Error during export:', error)
    });
  };

  const csvExportHandler = () => {
    const csvData = { ...data, csv: 1 };
    extractData(csvData, {
      onSuccess: data => handleExport(data),
      onError: error => console.error('Error during export:', error)
    });
  };

  const { data: filesData } = useQuery(
    ['GET_PROJECT_FILES', id],
    () => getFiles({ projectISbb_datasheep_projectsID: id, page: 0, limit: 10000 }),
    {
      enabled: !!id
    }
  );

  const emptyTextFiles = Array.isArray(filesData?.data)
    ? filesData.data.filter(file => file.extracted_textISsmallplaintextbox === '' || file.extracted_textISsmallplaintextbox === null)
    : [];
  const emptyTextFilesQuantity = emptyTextFiles.length;

  const handleOpenFilesSpecModal = () => {
    setShowFilesSpecModalLocal(true);
  };

  const handleOpenFieldsSpecModal = () => {
    setShowFieldsSpecModalLocal(true);
  };

  return (
    <>
      <div style={{float:'right'}}>

        <Button type="button" variant="falcon-primary" onClick={() => setShowFilesModal(true)} style={{float:'left',marginRight:'15px'}}>
          Add files
        </Button>

        <Dropdown  style={{float:'left',marginRight:'15px'}}>
          <Dropdown.Toggle variant="falcon-primary" className="w-100">
            Extract...
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item className="text-warning" style={{cursor:'help'}}>These actions consume credits:</Dropdown.Item>
            <Dropdown.Item onClick={() => extractFiles(data)}>Extract fields</Dropdown.Item>
            <Dropdown.Item onClick={() => handleOpenFieldsSpecModal()}>Extract specific fields</Dropdown.Item>
            <Dropdown.Item onClick={() => extractEmptyFiles(data)}>Extract empty fields</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={() => extractText(data)}>Extract text</Dropdown.Item>
            <Dropdown.Item onClick={() => extractEmptyText(data)}>Extract empty text</Dropdown.Item>
            <Dropdown.Item onClick={() => handleOpenFilesSpecModal()}>Extract text from specific files</Dropdown.Item>
            {emptyTextFilesQuantity > 0 && (
              <>
              <Dropdown.Divider />
              <Dropdown.Item as="div" className="d-flex align-items-center text-danger" style={{ cursor: 'default' }}>
                <i className="bi bi-exclamation-triangle me-2 text-warning" style={{ fontSize: '1.2rem' }}></i>
                Note you have {emptyTextFilesQuantity} records without Extracted Text
              </Dropdown.Item>
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown  style={{float:'left'}}>
          <Dropdown.Toggle variant="falcon-primary" className="w-100">
            Export Data
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={textExportHandler}>Export to .xlsx</Dropdown.Item>
            <Dropdown.Item onClick={csvExportHandler}>Export to .csv</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        
      </div>

      <ModalExtract
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        bodyText={modalBodyText}
        showProgressBar={showProgressBar}
        progress={progress}
      />

      <FilesSpecModal
        isVisible={showFilesSpecModal}
        closeModal={() => setShowFilesSpecModalLocal(false)}
        extractSelectedFiles={selectedFiles => extractText({ fileISbb_datasheep_projects_filesID: id, selectedFiles })}
      />

      <FieldsSpecModal
        isVisible={showFieldsSpecModal}
        closeModal={() => setShowFieldsSpecModalLocal(false)}
        extractSelectedFields={selectedFields => extractFiles({ fileISbb_datasheep_projects_filesID: id, selectedFields })}
      />
    </>
  );
};

FieldsSideMenu.propTypes = {
  setShowFilesModal: PropTypes.func.isRequired
};

export default FieldsSideMenu;
